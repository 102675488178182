const APPLICATION_URL = process.env.REACT_APP_API_URL_NODE;

export const endpoint = {
  API_BASE_URL: "http://quiz.clouddeploy.in/api/v1",
  // API_BASE_URL: "http://quiz.clouddeploy.in/api/v1",

  LOGIN: "/auth/login",

  //gallery
};
