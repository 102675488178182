import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // For extracting EventId from URL
import { Spinner } from "react-bootstrap"; // For loading spinner
import { useSelector } from "react-redux";
import "../../App.css";
import "../../containers/CongratulationPage/CongratulationPage.css";
import FireworkAnimation from "../../FireworkAnimation";

function CongratulationPage() {
  const { EventId } = useParams(); // Get EventId from the URL
  const [eventData, setEventData] = useState(null); // Store event data
  const [loading, setLoading] = useState(true); // Loading state
  const { authData, role } = useSelector((state) => state.loginSlice);

  // Function to fetch event details
  const getEventDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `http://quiz.clouddeploy.in/api/v1/quiz/getEventById/${EventId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (data.status === "success") {
        setEventData(data.data); // Set the fetched event data
        console.log("Event fetched successfully:", data.data);
      } else {
        console.error("Failed to fetch event:", data.message);
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch event details on component mount
  useEffect(() => {
    if (EventId) {
      getEventDetails();
    }
  }, [EventId]);

  if (loading) {
    return (
      <div className="loading-container">
        <Spinner animation="border" variant="primary" />
        <span>Loading...</span>
      </div>
    );
  }

  if (!eventData) {
    return <div>Error: Event data could not be fetched.</div>;
  }

  return (
    <>
      <div className="congratulation-page">
        <div className="congratulation-message">
          <h1>Congratulations!</h1>
          <p>The event has ended. Here are the results:</p>
        </div>

        <div className="leaderboard">
          <h2>Leaderboard</h2>
          <table>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Team Name</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {eventData.teams
                .sort((a, b) => b.TeamPoints - a.TeamPoints) // Sort teams by points
                .map((team, index) => (
                  <tr key={team.TeamId}>
                    <td>{index + 1}</td>
                    <td>{team.TeamName}</td>
                    <td>{team.TeamPoints}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default CongratulationPage;
