import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";

import { resetData } from "../../features/login/LoginSlice";
import { useEffect, useState } from "react";

export const useQuizPage = (props) => {
  const { EventId } = props;
  const { authData, role } = useSelector((state) => state.loginSlice);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [questionData, setQuestionData] = useState(null);
  const [canSelectOptions, setCanSelectOptions] = useState(false);

  const [showAnswer, setShowAnswer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(20);
  const [isOpen, setisOpen] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [submitLoading, setsubmitLoading] = useState("");
  const [isCategoryDisabled, setIsCategoryDisabled] = useState(false);

  const [eventData, setEventData] = useState({ teams: [] });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [allQuestionsCompleted, setAllQuestionsCompleted] = useState(false);
  const [loadingQuestion, setLoadingQuestion] = useState(false);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const enterFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    }
    setIsFullScreen(true);
  };

  const exitFullScreen = () => {
    if (
      !document.fullscreenElement &&
      !document.webkitFullscreenElement &&
      !document.mozFullScreenElement &&
      !document.msFullscreenElement
    ) {
      return;
    }

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setIsFullScreen(false);
  };

  useEffect(() => {
    const handleUserClick = () => {
      if (!isFullScreen) {
        console.log("Entering full screen...");
        enterFullScreen();
      }
    };

    const handleKeydown = (event) => {
      if (event.key === "Escape" && isFullScreen) {
        exitFullScreen();
      }
    };

    const handleFullScreenChange = () => {
      console.log("Full-screen change detected.");
      const isCurrentlyFullScreen =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;

      console.log("Is full screen:", isCurrentlyFullScreen);
      setIsFullScreen(!!isCurrentlyFullScreen);

      // Remove the click listener only if we exit fullscreen
      if (!isCurrentlyFullScreen) {
        console.log("Exiting full screen. Removing click listener.");
        document.removeEventListener("click", handleUserClick);
      }
    };

    // Add event listeners for fullscreen change, keydown (Escape), and user click.
    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("msfullscreenchange", handleFullScreenChange);

    if (!isFullScreen) {
      // Add the click listener to trigger fullscreen only when not in fullscreen mode
      document.addEventListener("click", handleUserClick);
    }

    return () => {
      document.removeEventListener("click", handleUserClick);
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullScreenChange
      );
    };
  }, [isFullScreen]);
  const handlePauseClick = () => {
    setActionType("pause");
    setIsModalOpen(true);
  };

  const handleEndClick = () => {
    setActionType("end");
    setIsModalOpen(true);
  };

  const getEventDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `http://quiz.clouddeploy.in/api/v1/quiz/getEventById/${EventId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (data.status === "success") {
        setEventData(data.data);
        console.log("Event fetched successfully:", data.data);
      } else {
        console.error("Failed to fetch event:", data.message);
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (EventId) {
      getEventDetails();
    }
  }, [EventId]);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const closeModal = () => {
    setDropdownVisible(false);
  };

  const teams = eventData?.teams || [];
  const [draggedTeamIndex, setDraggedTeamIndex] = useState(null);

  const handleDragStart = (e, index) => {
    setDraggedTeamIndex(index);
    e.target.style.opacity = "0.5";
  };

  const handleDragEnd = (e) => {
    e.target.style.opacity = "1";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const updateTeamPositions = async (updatedTeams) => {
    const payload = {
      EventId: EventId,
      teamPositions: updatedTeams.map((team) => ({
        TeamId: team.TeamId,
        Position: team.Position,
      })),
    };

    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/quiz/changeEventTeamPositions",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      console.log("Team positions updated successfully:", data);
    } catch (error) {
      console.error("Error updating team positions:", error);
    }
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    if (draggedTeamIndex === null || draggedTeamIndex === targetIndex) return;

    const updatedTeams = [...teams];
    const [draggedTeam] = updatedTeams.splice(draggedTeamIndex, 1);
    updatedTeams.splice(targetIndex, 0, draggedTeam);

    const updatedTeamsWithPosition = updatedTeams.map((team, index) => ({
      ...team,
      Position: index + 1,
    }));

    setEventData((prev) => ({
      ...prev,
      teams: updatedTeamsWithPosition,
    }));

    setDraggedTeamIndex(null);

    updateTeamPositions(updatedTeamsWithPosition);
  };

  const showScroll = teams.length > 4;

  useEffect(() => {
    fetchCategories();
  }, []);

  const deleteUsedQuestions = async () => {
    try {
      setsubmitLoading(true);
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/quiz/reset",
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        toast.success(data.message);
        handleClear();
        setisOpen(false);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error deleting questions:", error);
      toast.error("An error occurred while deleting questions.");
    } finally {
      setsubmitLoading(false);
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    // setOpenAlert(false);
    dispatch(resetData());
    navigate("/", { replace: true });
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/quiz/getCategories",
        {
          method: "GET", // Optional since GET is the default
          headers: {
            Authorization: `Bearer ${authData}`, // Add token here
            "Content-Type": "application/json", // Optional, but good to specify
          },
        }
      );
      const data = await response.json();
      console.log(data.data);

      setCategories(data?.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategoryId(categoryId);
    setAllQuestionsCompleted(false);
    setErrorMessage("");
  };

  const handleGetQuestion = async () => {
    if (!selectedCategoryId) {
      alert("Please select a category first.");
      return;
    }

    setIsCategoryDisabled(true);
    setLoadingQuestion(true); // Set loading state

    try {
      const response = await fetch(
        `http://quiz.clouddeploy.in/api/v1/quiz/getQuestionByCategoryId/${selectedCategoryId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
        }
      );
      const questionData = await response.json();

      if (questionData.status === "failure") {
        setErrorMessage(
          "All the questions in this category have been used. Please select another category or Reset the Questions."
        );
        setIsCategoryDisabled(false);
        setAllQuestionsCompleted(true);
        setLoadingQuestion(false);
        return;
      }

      setErrorMessage("");
      setQuestionData(questionData.data);
      setShowAnswer(false);
      setTimer(20);
      setCanSelectOptions(true);
      setAllQuestionsCompleted(false);
    } catch (error) {
      console.error("Error fetching question:", error);
      toast.error("An error occurred while fetching the question.");
    } finally {
      setLoadingQuestion(false); // End loading state
      setIsCategoryDisabled(false);
    }
  };

  useEffect(() => {
    let timerInterval;
    if (questionData && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(timerInterval);
    }

    return () => clearInterval(timerInterval);
  }, [questionData, timer]);

  const updateTeamScores = async (EventId, teams) => {
    const payload = {
      EventId: EventId,
      marks: teams.map((team) => ({
        teamId: team.TeamId,
        teamMarks: team.scoreResult, // Use numeric scoreResult
      })),
    };

    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/quiz/submitResult",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Scores updated successfully:", result);
        toast.success("Scores updated successfully!");

        if (result.updatedTeams) {
          setEventData((prev) => ({
            ...prev,
            teams: prev.teams.map((team) => {
              const updatedTeam = result.updatedTeams.find(
                (t) => t.teamId === team.TeamId
              );
              return updatedTeam
                ? { ...team, TeamPoints: updatedTeam.totalScore }
                : team;
            }),
          }));
        }
      } else {
        console.error("Error updating scores:", result.message);
        toast.error(result.message || "Failed to update scores.");
      }
    } catch (error) {
      console.error("Error in API call:", error);
      toast.error("An error occurred while updating scores.");
    }
  };

  const handleRevealAnswer = async () => {
    if (!questionData) {
      console.error("No question data available to reveal the answer.");
      return;
    }

    const correctAnswer = questionData?.answer?.trim().charAt(0).toLowerCase();

    const updatedTeams = eventData.teams.map((team) => {
      const selectedOption = team.selectedOption
        ?.trim()
        .slice(-1)
        .toLowerCase();
      const isActiveTeam = team.CurrentTurn === 1;
      const isCorrect = selectedOption === correctAnswer;

      const scoreChange = selectedOption
        ? isCorrect
          ? isActiveTeam
            ? 2
            : 1
          : isActiveTeam
          ? -2
          : -1
        : 0;

      return {
        ...team,
        scoreResult: scoreChange,
        // Do not clear selectedOption here
      };
    });

    setEventData((prev) => ({
      ...prev,
      teams: updatedTeams,
    }));

    setShowAnswer(true); // Answer revealed

    try {
      await updateTeamScores(EventId, updatedTeams);
      console.log("Scores updated successfully.");
    } catch (error) {
      console.error("Failed to update scores:", error);
    }
  };

  const handleClear = async () => {
    setEventData((prevEventData) => ({
      ...prevEventData,
      teams: prevEventData.teams.map((team) => ({
        ...team,
        selectedOption: null, // Clear selectedOption only on Next
      })),
    }));

    setQuestionData(null);
    setShowAnswer(false);
    setTimer(20);
    setIsCategoryDisabled(false);

    try {
      await getEventDetails();
      console.log("Event details fetched successfully.");
    } catch (error) {
      console.error("Error fetching updated event details:", error);
    }
  };
  const handleOptionSelect = (teamId, option) => {
    if (!canSelectOptions) {
      toast.error("Please click 'Get Question' to enable option selection.");
      return;
    }

    if (showAnswer) {
      toast.error(
        "You cannot change your selection after the answer is revealed."
      );
      return;
    }

    setEventData((prevEventData) => ({
      ...prevEventData,
      teams: prevEventData.teams.map((team) =>
        team.TeamId === teamId ? { ...team, selectedOption: option } : team
      ),
    }));
  };
  const getButtonAnimation = (team, option) => {
    if (!showAnswer) return {};
    if (team.correctOption === option) {
      return { backgroundColor: "green", scale: 1.2 };
    }

    if (team.selectedOption === option && team.correctOption !== option) {
      return { backgroundColor: "red", scale: 1.2 };
    }

    return {};
  };

  const handlePause = () => {
    navigate("/Events");
  };

  const handleEnd = async () => {
    try {
      const response = await fetch(
        `http://quiz.clouddeploy.in/api/v1/quiz/changeEventState/${EventId}?EventStatus=End`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("end res", response);
      const result = await response.json();
      if (result.status === "success") {
        toast.success("Event ended successfully!");
        navigate(`/event/${EventId}`);
      } else {
        toast.error(
          result.message || "Failed to end the event. Please try again."
        );
      }
    } catch (error) {
      console.error("Error in API request:", error);
    }
  };
  const handleEndConfirmation = async () => {
    await handleEnd();

    navigate(`/congratulations/${EventId}`);
  };

  return {
    loading,
    role,
    timer,
    showAnswer,
    questionData,
    handleClear,
    isOpen,
    setisOpen,
    // teamsData,
    ErrorMessage,
    submitLoading,
    categories,
    handleCategoryChange,
    selectedCategoryId,
    showScroll,
    dropdownVisible,
    toggleDropdown,
    closeModal,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleDrop,
    deleteUsedQuestions,
    handleLogout,
    handleGetQuestion,
    handleRevealAnswer,
    teams,
    getButtonAnimation,
    handleOptionSelect,
    eventData,
    isCategoryDisabled,
    handlePause,
    handleEndConfirmation,
    handlePauseClick,
    handleEndClick,
    isModalOpen,
    actionType,
    setIsModalOpen,
    allQuestionsCompleted,
    loadingQuestion,
    isFullScreen,
  };
};
