import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { createGlobalStyle, ThemeProvider } from "styled-components";

// import Header from "./Header/Header";
// import Footer from "./Footer/footer";
import NavBar from "../containers/Navbar/Navbar";

// Styles Import
import styles from "./index.module.css";
import FullPageLoading from "../FullPageLoading/FullPageLoading";

const Layout = ({ children }) => {
  const { loading } = useSelector((state) => state.fullPageLoaderSlice);
  const { role } = useSelector((state) => state.loginSlice);
  console.log("role", role.toLowerCase());

  return (
    <>
      <ThemeProvider theme={{ fontFamily: "Poppins" }}>
        <div className="d-lg-flex d-sm-flex ">
          {role.toLowerCase() === "admin" ? <NavBar /> : ""}
          {/* <Header /> */}
          <main className={styles.main}>
            {children}
            {loading ? <FullPageLoading /> : null}
          </main>
        </div>
        {/* <Footer /> */}
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Layout;
