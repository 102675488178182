import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useQuestionList = () => {
  const { authData } = useSelector((state) => state.loginSlice);
  const [questions, setQuestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getCategories();
  }, []);
  const getCategories = async () => {
    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/quiz/getCategories",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setCategories(data?.data || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getQuestions = async () => {
    try {
      console.log("called");

      let url = `http://quiz.clouddeploy.in/api/v1/quiz/getQuestionWithOption?iPageNumber=${currentPage}&iPageSize=${50}`;
      if (selectedCategory) {
        url += `&iCategoryId=${selectedCategory}`;
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authData}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log(data);

      setQuestions(data?.data.QuestionData || []);
      setTotalCount(data?.data.totalRecords);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handlePageClick = (number) => {
    setCurrentPage(number);
  };

  useEffect(() => {
    getQuestions();
  }, [selectedCategory, currentPage]);

  const sampleData = [
    {
      question: "What is 2+2?",
      options: ["3", "4", "5"],
      correctAnswer: "4",
    },
    {
      question: "What is the capital of France?",
      options: ["Paris", "Berlin", "Madrid"],
      correctAnswer: "Paris",
    },
  ];

  const processAnswers = (answers) => {
    const options = answers?.map((ans) => ans.Answer);

    const correctAnswer =
      answers?.find((ans) => ans.IsCorrect === 1)?.Answer || "N/A";
    return { options, correctAnswer };
  };

  return {
    questions,
    categories,
    selectedCategory,
    setSelectedCategory,
    totalCount,
    currentPage,
    handlePageClick,
    sampleData,
    processAnswers,
  };
};
