import React from "react";
import { useUploadQuestions } from "../../customHook/UploadQuestions/useUploadQuestions";
import "../../containers/UploadQuestions/UploadQuestions.css";

const UploadQuestions = () => {
  const talonProps = useUploadQuestions();
  const {
    lastId,
    category,
    setCategory,
    categories,
    handleFileUpload,
    fileData1,
    fileData2,
    setFileData1,
    setFileData2,
    jsonData1,
    jsonData2,
    setJsonData1,
    setJsonData2,
    renderTable,
    error,
    handleUpload,
  } = talonProps;
  return (
    <div className="p-6  mx-auto bg-white">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">
        Upload Two Excel Files
      </h1>
      <div className="font-semibold text-xl mb-4">
        Last Inserted Question Id:{lastId}
      </div>
      <div className="flex gap-4">
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Select the category you want to upload the questions
          </label>
          <select
            name="categories"
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value={""}>Select Option</option>
            {categories?.map((cate) => {
              return (
                <option value={cate.iCategoryId}>{cate.sCategoryName}</option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            File 1(Questions):
          </label>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => handleFileUpload(e, setFileData1, setJsonData1)}
            className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={!category}
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            File 2(Answers):
          </label>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => handleFileUpload(e, setFileData2, setJsonData2)}
            className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={!category}
          />
        </div>
      </div>

      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-700 mb-2">
          Preview of File 1:
        </h2>
        {renderTable(jsonData1) || (
          <p className="text-gray-500">No data uploaded yet.</p>
        )}
      </div>

      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-700 mb-2">
          Preview of File 2:
        </h2>
        {renderTable(jsonData2) || (
          <p className="text-gray-500">No data uploaded yet.</p>
        )}
      </div>
      {error && <p className="text-red-600 text-sm mb-6">{error}</p>}

      <button
        className={`py-2 px-4 rounded-md font-medium transition duration-200 ${
          !jsonData1 && !jsonData2
            ? "bg-gray-400 text-gray-700 cursor-not-allowed"
            : "bg-blue-500 text-white hover:bg-blue-600"
        }`}
        disabled={!jsonData1 && !jsonData2}
        onClick={handleUpload}
      >
        Upload
      </button>
    </div>
  );
};

export default UploadQuestions;
