import React from "react";
import { LineWave } from "react-loader-spinner";

import classes from "./FullPageLoading.module.css";

const FullPageLoading = () => {
  return (
    <div className={classes.rootLoading}>
      <LineWave
        height="200"
        width="200"
        color="#bf2026"
        ariaLabel="line-wave"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        firstLineColor=""
        middleLineColor=""
        lastLineColor=""
      />
    </div>
  );
};

export default FullPageLoading;
