import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { useToasts } from "react-toast-notifications";

export const useEventDetails = () => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalCount, setTotalCount] = useState(0);
  const { addToast } = useToasts();
  const { EventId } = useParams();
  const { authData } = useSelector((state) => state.loginSlice);

  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teams, setTeams] = useState([]);
  const [teamTitle, setTeamTitle] = useState("");
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [showUpdateTeamModal, setShowUpdateTeamModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const convertToInputValue = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 16);
  };

  const fetchEventDetails = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `http://quiz.clouddeploy.in/api/v1/quiz/getEventById/${EventId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        setEventDetails({
          ...data.data.eventDetails,
          EventDate: convertToInputValue(data.data.eventDetails.EventDate),
        });
      } else {
        setError("Failed to fetch event: " + data.message);
      }
    } catch (error) {
      setError("Error fetching event details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (EventId) {
      fetchEventDetails();
    } else {
      setError("EventId is missing.");
      setLoading(false);
    }
  }, [EventId]);

  const fetchTeams = async () => {
    try {
      const response = await fetch(
        `http://quiz.clouddeploy.in/api/V1/teams/getTeamsByEventId/${EventId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();

      if (data.status === "success" && data.data && data.data.length > 0) {
        setTeams(data.data);
      } else {
        setError("No teams found for this event.");
      }
    } catch (error) {
      console.error("Error fetching teams:", error);
      setError("Error fetching teams.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, [EventId]);

  const convertToUTC = (dateString) => {
    const localDate = new Date(dateString);
    const utcDate = new Date(
      localDate.getTime() - localDate.getTimezoneOffset() * 60000
    );
    return utcDate.toISOString();
  };

  const handleEventChanges = async () => {
    console.log("Button clicked", eventDetails);

    if (!eventDetails) {
      console.error("Event details are missing");
      return;
    }

    const eventDate = convertToUTC(eventDetails.EventDate);

    const updatedEvent = {
      EventId: eventDetails.EventId,
      EventTitle: eventDetails.EventTitle,
      EventType: eventDetails.EventType,
      Date: eventDate,
    };

    try {
      const response = await fetch(
        `http://quiz.clouddeploy.in/api/V1/quiz/updateEvent`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedEvent),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        console.error("API Error:", error);
        addToast(error.message, {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }

      const data = await response.json();

      setEventDetails((prevDetails) => ({
        ...prevDetails,
        ...updatedEvent,
      }));
      addToast(data.message, {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      console.error("Error updating event:", error);
      addToast("An error occurred while updating the event.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const fetchMembers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `http://quiz.clouddeploy.in/api/V1/member/getMembers`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        setMembers(result.data.members || []);
      } else {
        addToast(result.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      console.error("Error fetching members:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const handleMemberClick = (member) => {
    console.log("member", member);

    if (selectedMembers.some((m) => m.iMemberId === member.iMemberId)) {
      setSelectedMembers((prev) =>
        prev.filter((m) => m.iMemberId !== member.iMemberId)
      );
    } else {
      setSelectedMembers((prev) => [...prev, member]);
    }
  };

  const handleRemoveMember = (member) => {
    setSelectedMembers((prev) =>
      prev.filter((m) => m.iMemberId !== member.iMemberId)
    );
  };

  const handleCreateTeam = async () => {
    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/teams/createTeam",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            EventId: EventId,
            TeamTitle: teamTitle,
            TeamMembers: selectedMembers.map((member) => member.iMemberId),
          }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        addToast(result.message, {
          appearance: "success",
          autoDismiss: true,
        });
        setShowCreateTeamModal(false);
        setTeamTitle("");
        setSelectedMembers([]);

        fetchTeams();
      } else {
        addToast(result.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      console.error("Error creating team:", error);
    }
  };

  const handleOpenUpdateModal = (team) => {
    console.log("team", team);

    setSelectedTeam(team);
    setTeamTitle(team.TeamTitle);
    setSelectedMembers(
      team.TeamMembers.map((member) => {
        return {
          iMemberId: member.MemberId,
          sMemberName: member.MemberName,
        };
      })
    );
    setShowUpdateTeamModal(true);
  };

  const handleUpdateTeam = async () => {
    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/teams/updateTeam",
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            TeamId: selectedTeam.TeamId,
            TeamTitle: teamTitle,
            TeamMembers: selectedMembers.map((member) => member.iMemberId),
          }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        addToast(result.message, {
          appearance: "success",
          autoDismiss: true,
        });
        setShowUpdateTeamModal(false);
        setTeamTitle("");
        setSelectedMembers([]);
        setSelectedTeam(null);
        fetchTeams();
      } else {
        addToast(result.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      console.error("Error updating team:", error);
    }
  };

  return {
    loading,
    error,
    eventDetails,
    setEventDetails,
    teams,
    // currentPage,
    // totalCount,
    // handlePageClick,
    handleEventChanges,
    handleMemberClick,
    handleRemoveMember,
    showCreateTeamModal,
    setShowCreateTeamModal,
    members,
    teamTitle,
    setTeamTitle,
    selectedMembers,
    handleCreateTeam,
    handleOpenUpdateModal,
    handleUpdateTeam,
    showUpdateTeamModal,
    setShowUpdateTeamModal,
  };
};
