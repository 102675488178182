import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useToasts } from "react-toast-notifications";
export const useUpcomingEvents = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const { authData } = useSelector((state) => state.loginSlice);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalCount, setTotalCount] = useState(0);
  const [eventTitle, setEventTitle] = useState("");
  const [eventType, setEventType] = useState("");
  const [sortBy, setSortBy] = useState("dtDate");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentEventId, setCurrentEventId] = useState(null);
  const [modalAction, setModalAction] = useState(null);

  const [newEventTitle, setNewEventTitle] = useState("");
  const [newEventType, setNewEventType] = useState("");
  const [newEventDate, setNewEventDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const openModal = (action, eventId) => {
    setModalAction(action);
    setCurrentEventId(eventId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalAction(null);
    setCurrentEventId(null);
    setErrorMessage("");
  };

  const openCreateEventModal = () => {
    setNewEventTitle("");
    setNewEventType("");
    setNewEventDate("");
    openModal("create");
  };

  const closeCreateEventModal = () => {
    closeModal();
  };

  const handleCreateEvent = async () => {
    if (!newEventTitle || !newEventType || !newEventDate) {
      toast.error("All fields are required.");
      return;
    }

    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/quiz/createEvent",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            EventTitle: newEventTitle,
            EventType: newEventType,
            Date: newEventDate,
          }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        addToast(result.message, {
          appearance: "success",
          autoDismiss: true,
        });
        closeCreateEventModal();

        fetchEvents();
      } else {
        addToast(result.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  const handleEventAction = async () => {
    if (!currentEventId) return;

    if (modalAction === "resume") {
      navigate(`/quiz/${currentEventId}`);
    } else if (modalAction === "start") {
      try {
        const response = await fetch(
          `http://quiz.clouddeploy.in/api/v1/quiz/changeEventState/${currentEventId}?EventStatus=Start`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${authData}`,
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        if (result.status === "success") {
          toast.success("Event started successfully!");
          closeModal();
          navigate(`/quiz/${currentEventId}`);
        } else {
          setErrorMessage(result.message || "An error occurred.");
        }
      } catch (error) {
        console.error("Error starting the event:", error);
        setErrorMessage(error.result?.data?.message || "An error occurred.");
      }
    }
  };

  const handleSort = (sortField, sortOrder) => {
    setSortBy(sortField);
    setSortDirection(sortOrder);
  };

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const encodedEventTitle = encodeURIComponent(eventTitle.trim());
      const encodedEventType = encodeURIComponent(eventType.trim());
      const response = await fetch(
        `http://quiz.clouddeploy.in/api/v1/quiz/getEvents?sEventTitle=${encodedEventTitle}&sEventType=${encodedEventType}&=${sortBy}&sortOrder=${sortDirection}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authData}`,
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        setEvents(result.data || []);
        toast.success(result.message);
      } else {
        toast.error(result.message || "Failed to fetch events.");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      toast.error("An error occurred while fetching events.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [eventTitle, eventType, sortDirection, sortBy]);

  // useEffect(() => {
  //   fetchEvents();
  // }, [currentPage]);

  // const handlePageClick = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };
  console.log("currentEventId", currentEventId);

  return {
    loading,
    events,
    // currentPage,
    // totalCount,
    // handlePageClick,
    eventTitle,
    setEventTitle,
    eventType,
    setEventType,
    sortDirection,
    sortBy,
    handleSort,

    // CreateBody,

    openModal,
    closeModal,
    modalIsOpen,
    handleEventAction,
    modalAction,
    currentEventId,
    newEventType,
    setNewEventType,
    newEventDate,
    setNewEventDate,
    closeCreateEventModal,
    handleCreateEvent,
    openCreateEventModal,
    newEventTitle,
    setNewEventTitle,
    errorMessage,
  };
};
