import * as XLSX from "xlsx";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
export const useUploadQuestions = () => {
  const { authData } = useSelector((state) => state.loginSlice);
  const [fileData1, setFileData1] = useState(null);
  const [fileData2, setFileData2] = useState(null);
  const [jsonData1, setJsonData1] = useState(null);
  const [jsonData2, setJsonData2] = useState(null);
  const [error, setError] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [lastId, setlastId] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getCategories();
    getLastId();
  }, []);

  const handleFileUpload = (event, setFileData, setJsonData) => {
    const file = event.target.files[0];

    if (
      !file ||
      ![
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ].includes(file.type)
    ) {
      setError("Please upload a valid Excel file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      let data = XLSX.utils.sheet_to_json(worksheet);

      if (data.length === 0) {
        setError("The uploaded Excel file is empty or improperly formatted.");
        return;
      }
      data = data.map((row) => {
        let { iAnswerId, ...rest } = row;
        return rest;
      });
      setError("");
      setFileData(file);
      setJsonData(data);
    };
    reader.readAsBinaryString(file);
  };

  const handleUpload = async () => {
    if (!jsonData1 || !jsonData2) {
      setError("Please upload both Excel files before proceeding.");
      return;
    }

    try {
      const payload = { questionJson: jsonData1, answerJson: jsonData2 };
      const response = await axios.post(
        "http://quiz.clouddeploy.in/api/v1/quiz/upload",
        { ...payload, iCategoryId: category },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authData}`, // Add the token here
          },
        }
      );

      alert("Data uploaded successfully!");
      setFileData1(null);
      setFileData2(null);
      setJsonData1(null);
      setJsonData2(null);
      navigate("/questions");
    } catch (err) {
      console.error(err);
      setError("Failed to upload data. Please try again.");
    }
  };

  const getCategories = async () => {
    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/quiz/getCategories",
        {
          method: "GET", // Optional since GET is the default
          headers: {
            Authorization: `Bearer ${authData}`, // Add token here
            "Content-Type": "application/json", // Optional, but good to specify
          },
        }
      );
      const data = await response.json();

      setCategories(data?.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getLastId = async () => {
    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/quiz/lastQuestionId",
        {
          method: "GET", // Optional since GET is the default
          headers: {
            Authorization: `Bearer ${authData}`, // Add token here
            "Content-Type": "application/json", // Optional, but good to specify
          },
        }
      );
      const data = await response.json();

      setlastId(data?.data.lastInsertedQuestionId);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const renderTable = (data) => {
    if (!data || data.length === 0) return null;

    const headers = Object.keys(data[0]);

    return (
      <div className="h-[50vh] overflow-x-scroll">
        <table className="table-bordered min-w-full divide-y divide-gray-200 my-3 rounded-lg overflow-hidden bg-white shadow-md table-responsive-sm">
          <thead>
            <tr className="bg-gray-100">
              {headers.map((header) => (
                <th
                  key={header}
                  className="border border-gray-300 px-4 py-2 font-medium text-gray-700"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index} className="even:bg-gray-50">
                {headers.map((header) => (
                  <td
                    key={header}
                    className="border border-gray-300 px-4 py-2 text-gray-600"
                  >
                    {row[header]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  return {
    lastId,
    category,
    setCategory,
    categories,
    handleFileUpload,
    fileData1,
    fileData2,
    setFileData1,
    setFileData2,
    jsonData1,
    jsonData2,
    setJsonData1,
    setJsonData2,
    renderTable,
    error,
    handleUpload,
  };
};
