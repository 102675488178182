import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./rootReducer";

// Persist Config
const persistConfig = {
  key: "root",
  storage,
  transforms: [
    createTransform(
      (inboundState) => {
        return {
          ...inboundState,
          _persistedAt: Date.now(),
        };
      },

      (outboundState) => {
        const now = Date.now();
        const twentyFourHours = 24 * 60 * 60 * 1000;

        if (
          outboundState._persistedAt &&
          now - outboundState._persistedAt > twentyFourHours
        ) {
          console.log("State expired. Clearing persisted state.");
          return { authData: null };
        }

        return outboundState;
      }
    ),
  ],
};

const appReducer = (state, action) => {
  if (action.type === "SIGNOUT") {
    storage.removeItem("persist:root");
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
