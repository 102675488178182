import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import "../../containers/EventDetails/EventDetails.css";
import { Tabs, Tab, Button, Modal } from "react-bootstrap";
import { useEventDetails } from "../../customHook/EventDetails/useEventDetails";
import { FaEdit, FaTrash } from "react-icons/fa";

const EventDetails = () => {
  const { EventId } = useParams();
  const navigate = useNavigate();
  const talonProps = useEventDetails();

  const {
    loading,
    error,
    eventDetails,
    setEventDetails,
    teams,
    // currentPage,
    // totalCount,
    // handlePageClick,

    handleEventChanges,
    handleMemberClick,
    handleRemoveMember,
    showCreateTeamModal,
    setShowCreateTeamModal,
    members,
    teamTitle,
    setTeamTitle,
    selectedMembers,
    handleCreateTeam,
    handleOpenUpdateModal,
    handleUpdateTeam,
    showUpdateTeamModal,
    setShowUpdateTeamModal,
  } = talonProps;

  console.log("selectedMembers", selectedMembers);
  const showmembers = useMemo(() => {
    return (
      <div className="selected-members-container">
        <h5>Selected Members</h5>
        {selectedMembers.length === 0 ? (
          <p>No members selected</p>
        ) : (
          <ul className="selected-member-list">
            {selectedMembers.map((member) => (
              <li key={member.iMemberId} className="selected-member-item">
                {member.sMemberName}
                <button
                  className="remove-member-btn"
                  onClick={() => handleRemoveMember(member)}
                >
                  ✕
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }, [selectedMembers]);

  if (!eventDetails)
    return <div className="event-detail-page-container">Event not found.</div>;

  const isEditable =
    eventDetails.EventStatus === "Not Started" ||
    eventDetails.EventStatus === "Started";

  const isNotStartedOrNotOccurred =
    eventDetails.EventStatus === "Not Started" ||
    eventDetails.EventStatus === "Not Occurred";

  const isFinished = eventDetails.EventStatus === "Finished";
  const winnerTeam = eventDetails.WinnerTeamId
    ? {
        title: eventDetails.WinnerTeamTitle,
        points: eventDetails.WinnerTeamPoints,
      }
    : null;

  return (
    <div className="event-detail-page-container">
      <div className="d-flex justify-content-between align-items-center">
        <h1>{isEditable ? "Edit Event" : "Event Details"}</h1>
        <Button variant="secondary" onClick={() => navigate("/events")}>
          Back
        </Button>
      </div>

      <Tabs
        defaultActiveKey="details"
        id="controlled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="details" title="Event Details">
          <div>
            <label>Event Title</label>
            {isEditable ? (
              <input
                type="text"
                className="editable"
                value={eventDetails.EventTitle}
                onChange={(e) =>
                  setEventDetails({
                    ...eventDetails,
                    EventTitle: e.target.value,
                  })
                }
              />
            ) : (
              <p className="non-editable">{eventDetails.EventTitle}</p>
            )}
          </div>

          <div>
            <label>Event Type</label>
            {isEditable ? (
              <select
                className="editable"
                value={eventDetails.EventType}
                onChange={(e) =>
                  setEventDetails({
                    ...eventDetails,
                    EventType: e.target.value,
                  })
                }
              >
                <option value="Quiz">Quiz</option>
                <option value="Group Discussion">Group Discussion</option>
              </select>
            ) : (
              <p className="non-editable">{eventDetails.EventType}</p>
            )}
          </div>

          <div>
            <label>Event Date</label>
            {isEditable ? (
              <input
                type="datetime-local"
                className="editable"
                value={eventDetails.EventDate}
                onChange={(e) =>
                  setEventDetails({
                    ...eventDetails,
                    EventDate: e.target.value,
                  })
                }
              />
            ) : (
              <p className="non-editable">
                {new Date(eventDetails.EventDate).toLocaleDateString()}
              </p>
            )}
          </div>

          {isEditable && (
            <Button className="save-changes-btn" onClick={handleEventChanges}>
              Save Changes
            </Button>
          )}
        </Tab>
        {isNotStartedOrNotOccurred && (
          <Tab eventKey="teamFormation" title="Team Formation">
            <div className="row">
              <div className="col"></div>
              <div className="col d-flex justify-content-end">
                <button onClick={() => setShowCreateTeamModal(true)}>
                  Create Team
                </button>
              </div>
            </div>
            <div className="team-formation-container">
              {teams.length === 0 ? (
                <div>No teams found for this event.</div>
              ) : (
                <table className="team-table">
                  <thead>
                    <tr>
                      <th>Team Title</th>
                      <th>Team Members</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teams.map((team) => (
                      <tr key={team.TeamId}>
                        <td>{team.TeamTitle}</td>
                        <td>
                          <div className="team-members-container">
                            <ul className="team-members">
                              {team.TeamMembers.map((member) => (
                                <li key={member.MemberId}>
                                  {member.MemberName}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </td>
                        <td className="team-actions">
                          <button
                            className="edit-btn"
                            onClick={() => handleOpenUpdateModal(team)}
                          >
                            <FaEdit />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </Tab>
        )}
      </Tabs>
      {isFinished && (
        <div className="event-detail-page-leaderboard">
          <h2>Event Highlights</h2>
          <div className="event-detail-page-leaderboard-container">
            <div className="leaderboard-header">
              <span>Team Title</span>
              <span>Questions Owned</span>
              <span>Questions Attempted</span>
              <span>Correct Answers</span>
              <span>Wrong Answers</span>
              <span>Points</span>
            </div>
            {teams.length === 0 ? (
              <div>No teams available</div>
            ) : (
              teams.map((team) => (
                <div key={team.TeamId} className="leaderboard-details">
                  <span>{team.TeamTitle}</span>
                  <span>{team.QuestionsOwned}</span>
                  <span>{team.QuestionsAttempted}</span>
                  <span>{team.CorrectAnswers}</span>
                  <span>{team.WrongAnswers}</span>
                  <span>{team.TeamPoints} pts</span>
                </div>
              ))
            )}
          </div>
        </div>
      )}

      <Modal
        show={showCreateTeamModal}
        onHide={() => setShowCreateTeamModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="create-team-modal">
            <label className="team-title-label">Team Title</label>
            <input
              type="text"
              className="team-title-input"
              value={teamTitle}
              onChange={(e) => setTeamTitle(e.target.value)}
              placeholder="Enter team title"
            />

            <div className="available-members-container">
              <h5>Available Members</h5>
              {members.length === 0 ? (
                <p>No members available</p>
              ) : (
                <ul className="member-list">
                  {members.map((member) => (
                    <li
                      key={member.iMemberId}
                      className={`member-item ${
                        selectedMembers.some(
                          (m) => m.iMemberId === member.iMemberId
                        )
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleMemberClick(member)}
                    >
                      {member.sMemberName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="selected-members-container">
              <h5>Selected Members</h5>
              {selectedMembers.length === 0 ? (
                <p>No members selected</p>
              ) : (
                <ul className="selected-member-list">
                  {selectedMembers.map((member) => (
                    <li key={member.iMemberId} className="selected-member-item">
                      {member.sMemberName}
                      <button
                        className="remove-member-btn"
                        onClick={() => handleRemoveMember(member)}
                      >
                        ✕
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowCreateTeamModal(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={handleCreateTeam}
            disabled={!teamTitle || selectedMembers.length === 0}
          >
            Create Team
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showUpdateTeamModal}
        onHide={() => setShowUpdateTeamModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="update-team-modal">
            <label className="team-title-label">Team Title</label>
            <input
              type="text"
              className="team-title-input"
              value={teamTitle}
              onChange={(e) => setTeamTitle(e.target.value)}
              placeholder="Enter new team title"
            />

            <div className="available-members-container">
              <h5>Available Members</h5>
              {members.length === 0 ? (
                <p>No members available</p>
              ) : (
                <ul className="member-list">
                  {members.map((member) => (
                    <li
                      key={member.iMemberId}
                      className={`member-item ${
                        selectedMembers.some(
                          (m) => m.iMemberId === member.iMemberId
                        )
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleMemberClick(member)}
                    >
                      {member.sMemberName}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="selected-members-container">
              <h5>Selected Members</h5>
              {selectedMembers.length === 0 ? (
                <p>No members selected</p>
              ) : (
                <ul className="selected-member-list">
                  {selectedMembers.map((member) => (
                    <li key={member.iMemberId} className="selected-member-item">
                      {member.sMemberName}
                      <button
                        className="remove-member-btn"
                        onClick={() => handleRemoveMember(member)}
                      >
                        ✕
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowUpdateTeamModal(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={handleUpdateTeam}
            disabled={!teamTitle || selectedMembers.length === 0}
          >
            Update Team
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EventDetails;
