import Pagination from "react-js-pagination";
import { useQuestionList } from "../../customHook/QuestionList/useQuestionList";
import "../../containers/QuestionList/QuestionList.css";

const QuestionList = () => {
  const talonProps = useQuestionList();
  const {
    questions,
    categories,
    selectedCategory,
    setSelectedCategory,
    totalCount,
    currentPage,
    handlePageClick,
    sampleData,
    processAnswers,
  } = talonProps;
  return (
    <div className="overflow-auto mx-10 mt-10">
      {/* Header */}
      <div className="mb-4">
        <h1 className="text-3xl font-semibold">Question List</h1>
      </div>

      {/* Filter Dropdown */}
      <div className="mb-4 flex ">
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="px-4 py-2 border border-gray-300 rounded-md"
        >
          <option value="">Select Category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.iCategoryId}>
              {category.sCategoryName}
            </option>
          ))}
        </select>
      </div>

      {/* Questions Table */}
      <div className="overflow-auto">
        <table className="min-w-full table-auto border-collapse border border-gray-200">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2">
                Question Name
              </th>
              <th className="border border-gray-300 px-4 py-2">Options</th>
              <th className="border border-gray-300 px-4 py-2">
                Correct Answer
              </th>
            </tr>
          </thead>
          <tbody>
            {questions?.map((row, index) => {
              const { options, correctAnswer } = processAnswers(
                JSON.parse(row.AnswerOptions)
              );
              return (
                <tr key={index} className="text-center">
                  <td className="border border-gray-300 px-4 py-2">
                    {row?.sQuestion}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {options?.map((option, idx) => (
                      <div key={idx}>{option}</div>
                    ))}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {row.CorrectOption}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={20}
          totalItemsCount={totalCount}
          pageRangeDisplayed={5}
          onChange={handlePageClick}
          nextPageText={">"}
          prevPageText={"<"}
          firstPageText={"<<"}
          lastPageText={">>"}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </div>
  );
};

export default QuestionList;
