import React, { useEffect } from "react";
import "../../App.css";
import "../../containers/QuizPage/QuizPage.css";
import FireworkAnimation from "../../FireworkAnimation";
import logo from "../../img/fs-logo.png";
import eyeLogo from "../../img/eye-fill.png";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { DialogContent, DialogTitle } from "@mui/material";
import { Dialog, DialogActions, DialogContentText } from "@material-ui/core";
import { Loader as LoaderIcon } from "react-feather";
import { Button, Modal, Spinner } from "react-bootstrap";
import { MdOutlineDragIndicator } from "react-icons/md";
import { PiArrowArcRightBold, PiArrowBendRightDownBold } from "react-icons/pi";
import { RiUserSettingsLine } from "react-icons/ri";
import { useQuizPage } from "../../customHook/QuizPage/useQuizPage";
import { motion, AnimatePresence } from "framer-motion";
import { FaPersonChalkboard } from "react-icons/fa6";
import { SlBadge } from "react-icons/sl";
import { useParams } from "react-router";

function QuizPage() {
  const { EventId } = useParams();
  const talonProps = useQuizPage({ EventId });
  const {
    loading,
    role,
    timer,
    showAnswer,
    questionData,
    handleClear,
    isOpen,
    setisOpen,
    // teamsData,
    ErrorMessage,
    submitLoading,
    categories,
    handleCategoryChange,
    selectedCategoryId,
    showScroll,
    dropdownVisible,
    toggleDropdown,
    closeModal,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleDrop,
    deleteUsedQuestions,
    handleLogout,
    handleGetQuestion,
    handleRevealAnswer,
    teams,
    getButtonAnimation,
    handleOptionSelect,
    handleEndConfirmation,
    eventData,
    isCategoryDisabled,
    handlePause,
    handlePauseClick,
    handleEndClick,
    isModalOpen,
    actionType,
    setIsModalOpen,
    allQuestionsCompleted,
    loadingQuestion,
    isFullScreen,
  } = talonProps;

  const sortedTeams = [...eventData.teams].sort((a, b) =>
    a.TeamName.localeCompare(b.TeamName)
  );

  return (
    <div
      className={`mainContainer h-full py-4 ${
        isFullScreen ? "fullscreen" : ""
      }`}
    >
      <main>
        <div className="mx-10">
          <div className="eventStatus d-flex justify-content-end">
            <button className="pauseButton mr-2" onClick={handlePauseClick}>
              Pause
            </button>

            <button
              key={eventData.iEventId}
              className="endButton"
              onClick={handleEndClick}
            >
              End
            </button>
          </div>
          <header className="leftcontainer d-flex">
            <div className="col-md-6 quizHeading">Quiz</div>
            <div className="team-settings col-md-6 d-flex justify-content-end">
              <div className="team-container">
                <div>
                  <span
                    style={{
                      marginTop: "35px",
                      marginRight: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Team Allocation
                  </span>
                  <button
                    className="team-toggle-button"
                    onClick={toggleDropdown}
                  >
                    <RiUserSettingsLine />
                  </button>
                </div>
                {dropdownVisible && (
                  <div className="team-modal-overlay" onClick={closeModal}>
                    <div className="team-modal">
                      <button
                        className="close-modal-button"
                        onClick={closeModal}
                      >
                        &times;
                      </button>
                      <div className="team-modal-content">
                        {teams
                          .sort((a, b) => a.Position - b.Position)
                          .map((team, index) => (
                            <div
                              key={team.TeamId}
                              className={`big-team ${
                                team.CurrentTurn === 1 ? "team-active" : ""
                              }`}
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleDrop(e, index)}
                            >
                              <div className="team-number">{team.Position}</div>
                              <div
                                id={`team${index + 1}`}
                                className="big-team innerdrag"
                                draggable
                                onDragStart={(e) => handleDragStart(e, index)}
                                onDragEnd={handleDragEnd}
                              >
                                <MdOutlineDragIndicator /> {team.TeamName}
                                <span>
                                  <button className="teamScore">
                                    {team.TeamPoints}
                                  </button>
                                </span>
                              </div>
                              {index < teams.length - 1 && (
                                <button className="big-arrow-button">
                                  <PiArrowBendRightDownBold className="team-arrow" />
                                </button>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {role === "user" && (
              <div className="logoutBtn-container col-md-2">
                <button onClick={handleLogout} className="logoutBtn">
                  <RiLogoutCircleRLine size={22} /> Logout
                </button>
              </div>
            )}
          </header>
          <div className="mt-4">
            <div className="row">
              <div className="col-md-4">
                <div className="leftWhitecontainer ">
                  <h1 className="mb-4 categoryHeading">Choose Category</h1>
                  <select
                    onChange={handleCategoryChange}
                    value={selectedCategoryId}
                    disabled={isCategoryDisabled || showAnswer}
                    className={`category-select ${
                      isCategoryDisabled || showAnswer ? "disabled" : ""
                    }`}
                  >
                    <option value="" disabled>
                      {isCategoryDisabled
                        ? "Category selection locked until answered"
                        : "-- Select --"}
                    </option>
                    {categories?.map((category) => (
                      <option
                        key={category.iCategoryId}
                        value={category.iCategoryId}
                        className="selectOption"
                      >
                        {category.sCategoryName}
                      </option>
                    ))}
                  </select>

                  <div className="row">
                    <div className="col">
                      {selectedCategoryId && (
                        <button
                          disabled={
                            !selectedCategoryId || questionData || loading
                          }
                          onClick={handleGetQuestion}
                        >
                          {loading ? "Loading..." : "Get Question"}
                        </button>
                      )}
                    </div>
                    <div className="col d-flex items-end justify-end">
                      {allQuestionsCompleted && (
                        <button
                          className="resetBtn"
                          onClick={() => setisOpen(true)}
                        >
                          Reset Questions
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="team-scores">
                    <div
                      className="teams-scroll-container"
                      style={{ overflowY: "auto" }}
                    >
                      {sortedTeams.map((team) => (
                        <div
                          key={team.TeamId}
                          className={`team-card ${
                            team.CurrentTurn === 1 ? "team-active" : ""
                          }`}
                        >
                          <div className="team-info">
                            <h3 className="team-name">
                              {team.TeamName}
                              <span
                                style={{
                                  background: "yellow",
                                  marginLeft: "10px",
                                }}
                              >
                                {team.TeamPoints}
                              </span>
                            </h3>

                            {questionData && (
                              <div className="team-options">
                                {questionData.options.map((_, index) => (
                                  <motion.button
                                    key={index}
                                    className={`team-option ${
                                      team.selectedOption ===
                                      `Option ${String.fromCharCode(
                                        65 + index
                                      )}`
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleOptionSelect(
                                        team.TeamId,
                                        `Option ${String.fromCharCode(
                                          65 + index
                                        )}`
                                      )
                                    }
                                    disabled={showAnswer}
                                    animate={{
                                      scale:
                                        team.selectedOption ===
                                        `Option ${String.fromCharCode(
                                          65 + index
                                        )}`
                                          ? 1.2
                                          : 1,
                                    }}
                                    transition={{ duration: 0.2 }}
                                  >
                                    {`Option ${String.fromCharCode(
                                      65 + index
                                    )}`}
                                  </motion.button>
                                ))}
                              </div>
                            )}
                          </div>

                          {showAnswer && (
                            <div
                              className={`${
                                team.scoreResult > 0
                                  ? "team-score-correct"
                                  : team.scoreResult < 0
                                  ? "team-score-wrong"
                                  : "team-score-neutral"
                              }`}
                            >
                              {team.scoreResult > 0
                                ? `+${team.scoreResult}`
                                : team.scoreResult}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="score-disclaimer mt-3">
                      <strong>Score Disclaimer:</strong>
                      <p>
                        <span className="text-infor">+2</span> indicates a
                        correct answer for the active team,
                        <span className="text-warning">+1</span> indicates
                        partial credit for other teams,
                        <span className="text-danger">-1</span> indicates no
                        credit for incorrect answers by other teams,
                        <span className="text-danger">-2</span> indicates
                        incorrect answers by the active team.
                        <span className="text-secondary">
                          <b>0</b>
                        </span>{" "}
                        indicates no answer given by any team.
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="question-display">
                {ErrorMessage ? (
                  <span className="text-red-600">{ErrorMessage}</span>
                ) : (
                  <>
                    {loadingQuestion ? (
                      <div className="question-placeholder">
                        <p>Loading next question...</p>
                      </div>
                    ) : questionData ? (
                      <div className="actual-question">
                        <p>{questionData.questionText}</p>
                      </div>
                    ) : (
                      <div className="no-question">
                        <p>
                          Please select a category and click "Get Question".
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="col-md-8 leftcontainer">
                <div className="rightwhitecontainer mt-4 mt-lg-0">
                  <div className="flex justify-between">
                    {questionData && (
                      <span className="font-semibold text-xl">
                        Remaining Questions:
                        {questionData?.remainingQuestions || 0}
                      </span>
                    )}
                  </div>

                  {questionData && (
                    <>
                      <strong>Q. {questionData.question}</strong>
                      <ul>
                        {questionData?.options.map((option, index) => (
                          <li key={index}>{option}</li>
                        ))}
                      </ul>

                      <div
                        className={`timer ${
                          timer === 0 ? "time-up" : "time-running"
                        }`}
                      >
                        {timer > 0
                          ? `Time Remaining: ${timer} seconds`
                          : "Time up!"}
                      </div>

                      <button
                        className="reveal-scores-button"
                        onClick={handleRevealAnswer}
                        style={{
                          display: showAnswer ? "none" : "inline-block",
                        }}
                      >
                        Reveal Answer <img src={eyeLogo} alt="eye" />
                      </button>
                      {showAnswer && (
                        <div className="correctanswer">
                          <textarea readOnly>{questionData?.answer}</textarea>
                        </div>
                      )}

                      {showAnswer && (
                        <div className="d-flex justify-content-end mt-3">
                          <button
                            className="clear-button"
                            onClick={handleClear}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        pauseOnFocusLoss
        theme="light"
      /> */}
      <Modal show={isOpen} onHide={() => setisOpen(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to reset all the used questions?</p>
        </Modal.Body>
        <Modal.Footer>
          {submitLoading ? (
            <button className="delete" disabled={submitLoading}>
              <LoaderIcon size={20} className="indicator" />
            </button>
          ) : (
            <button
              onClick={deleteUsedQuestions}
              disabled={submitLoading}
              className="delete"
            >
              {submitLoading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                "Yes"
              )}
            </button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to {actionType === "pause" ? "pause" : "end"}{" "}
            the event?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setIsModalOpen(false)}
            className="cancel-button"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (actionType === "pause") {
                handlePause();
              } else if (actionType === "end") {
                handleEndConfirmation();
              }
              setIsModalOpen(false);
            }}
            className="confirm-button"
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default QuizPage;
