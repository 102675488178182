import React, { useState } from "react";
import "./AuthPage.css";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { endpoint } from "../api/endpoint";
import {
  saveAuthData,
  saveModule,
  saveRole,
} from "../features/login/LoginSlice";
import { apiHandler } from "../api";
// import { useNavigate } from "react-router";

export default function AuthPage() {
  const { authData } = useSelector((state) => state.loginSlice);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  // const navigate = useNavigate();
  const handleClose = () => {
    setOpenUpdateAlert(false);
  };

  const validate = () => {
    if (username === "" && password === "") {
      setOpenUpdateAlert(true);
      setResponseMessage("Please enter the Credentials to Login! ");
      return false;
    }
    if (username.trim() === "") {
      setOpenUpdateAlert(true);
      setResponseMessage("Please enter the Email Address!");
      return false;
    } else if (password.trim() === "") {
      setOpenUpdateAlert(true);
      setResponseMessage("Please enter the Password! ");
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validate()) {
      const response = await apiHandler({
        url: endpoint.LOGIN,
        method: "POST",
        data: {
          sEmail: username,
          sPassword: password,
        },
      });
      if (response.data.status === "success") {
        dispatch(saveAuthData(response.data.data.accessToken));
        dispatch(saveRole(response.data.data.sRole));
        dispatch(saveModule(response.data.data.module));
        window.location.href = "/quiz";
      } else {
        addToast(response.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      return;
    }
  };
  return (
    <div className="LoginPageWrapper">
      <div className="Row">
        <div className="Colmd8">
          <div className="maineformbox">
            <div className="newloginBox">
              <h3 className="LoginHeading">Welcome!</h3>
              <small>Continue to Login with Email and Password!</small>
              <div className="Mt4 frontlogin">
                <form onSubmit={handleSubmit}>
                  <div className="FormLabel">
                    <input
                      type="text"
                      className="FormControl"
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Email address"
                    />
                  </div>
                  <div className="FormLabel">
                    <input
                      className="FormControl"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                    />
                  </div>
                  <div>
                    <button className="SignInButton" type="submit">
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
