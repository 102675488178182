import React, { useState } from "react";
import "../../containers/UpcomingEvents/UpcomingEvents.css";
import { useUpcomingEvents } from "../../customHook/UpcomingEvents/useUpcomingEvents";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { FaPlay } from "react-icons/fa";
import { GrResume } from "react-icons/gr";
import { MdDelete, MdEdit } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router";

const UpcomingEvents = () => {
  const navigate = useNavigate();
  const {
    loading,
    events,
    // currentPage,
    // totalCount,
    // handlePageClick,
    eventTitle,
    setEventTitle,
    eventType,
    setEventType,
    sortDirection,
    sortBy,
    handleSort,
    openModal,
    closeModal,
    modalIsOpen,
    handleEventAction,
    modalAction,
    currentEventId,
    newEventType,
    setNewEventType,
    newEventDate,
    setNewEventDate,
    closeCreateEventModal,
    handleCreateEvent,
    openCreateEventModal,
    newEventTitle,
    setNewEventTitle,
    errorMessage,
  } = useUpcomingEvents();

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const renderStatusButton = (
    status,
    eventId,
    eventDate,
    winnerTitle,
    winnerPoints
  ) => {
    const isPastDate = new Date(eventDate) < new Date();
    const isEventNotOccurred =
      isPastDate && status !== "Finished" && status !== "Started";

    if (isEventNotOccurred) {
      return <div className="text-red-500">Event Not Occurred</div>;
    }

    if (status === "Started") {
      return (
        <button
          className="px-3 py-2 eventResumed"
          onClick={() => openModal("resume", eventId)}
        >
          <GrResume />
        </button>
      );
    } else if (status === "Not Started") {
      return (
        <button
          className="px-3 py-2 eventStarted"
          onClick={() => openModal("start", eventId)}
        >
          <FaPlay />
        </button>
      );
    } else if (status === "Finished") {
      return (
        <span>
          Winner: <strong>{winnerTitle}</strong> with
          <strong>{winnerPoints}</strong> points
        </span>
      );
    }
  };

  return (
    <div className="overflow-auto mx-10 mt-10">
      <div className="row">
        <div className="col mb-4">
          <h1 className="text-3xl font-semibold">All Events</h1>
        </div>
        <div className="col mb-4 d-flex justify-content-end">
          <button className="createEvent" onClick={openCreateEventModal}>
            Create Event
          </button>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-2 d-flex">
          <input
            type="text"
            placeholder="Search Event Title..."
            value={eventTitle}
            onChange={(e) => setEventTitle(e.target.value)}
            className="px-4 py-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="col d-flex justify-content-end">
          <select
            value={eventType}
            onChange={(e) => setEventType(e.target.value)}
            className="px-4 py-2 border border-gray-300 rounded-md"
          >
            <option value="">All</option>
            <option value="Quiz">Quiz</option>
            <option value="Group Discussion">Group Discussion</option>
          </select>
        </div>
      </div>

      <div className="overflow-auto">
        {loading ? (
          <p>Loading...</p>
        ) : events.length > 0 ? (
          <table className="min-w-full table-auto border-collapse border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2">
                  Event Title
                </th>
                <th className="border border-gray-300 px-4 py-2">Event Type</th>
                <th
                  className="border border-gray-300 px-4 py-2"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                  }}
                >
                  Event Date
                  {sortDirection === "DESC" ? (
                    <span
                      style={{ margin: "5px" }}
                      onClick={() => handleSort("dtDate", "ASC")}
                    >
                      <TiArrowSortedDown />
                    </span>
                  ) : (
                    <span
                      style={{ margin: "5px" }}
                      onClick={() => handleSort("dtDate", "DESC")}
                    >
                      <TiArrowSortedUp />
                    </span>
                  )}
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Event Status
                </th>
                <th className="border border-gray-300 px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => {
                const isPastDate = new Date(event.dtDate) < new Date();
                const isEventNotOccurred =
                  // isPastDate &&
                  event.sEventStatus !== "Finished" &&
                  event.sEventStatus !== "Started";

                return (
                  <tr key={event.iEventId} className="hover:bg-gray-200">
                    <td className="border border-gray-300 px-4 py-2">
                      {event.sEventTitle}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {event.sEventType}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {formatDate(event.dtDate)}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {renderStatusButton(
                        event.sEventStatus,
                        event.iEventId,
                        event.dtDate,
                        event.sWinnerTeamTitle,
                        event.iWinnerTeamPoints
                      )}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <span className="d-flex m-2">
                        {event.sEventStatus === "Finished" ? (
                          <FaEye
                            style={{ color: "green", cursor: "pointer" }}
                            onClick={() => navigate(`/event/${event.iEventId}`)}
                          />
                        ) : isEventNotOccurred ? (
                          <>
                            <MdEdit
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/event/${event.iEventId}`)
                              }
                            />
                            {/* &nbsp;&nbsp;
                            <MdDelete
                              style={{ color: "red", cursor: "pointer" }}
                            /> */}
                          </>
                        ) : (
                          <>
                            <MdEdit
                              style={{ color: "gray", cursor: "not-allowed" }}
                            />
                            {/* &nbsp;&nbsp;
                            <MdDelete
                              style={{ color: "gray", cursor: "not-allowed" }}
                            /> */}
                          </>
                        )}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>No upcoming events found.</p>
        )}
      </div>

      <Modal show={modalIsOpen} onHide={closeModal}>
        {modalAction === "start" && (
          <div>
            <p>
              Are you sure you want to start event with ID: {currentEventId}?
            </p>
            {errorMessage && (
              <div className="text-red-500 mt-2">{errorMessage}</div>
            )}
            {errorMessage ? (
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded"
                onClick={closeModal}
              >
                Ok
              </button>
            ) : (
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded"
                onClick={handleEventAction}
              >
                Start
              </button>
            )}
          </div>
        )}

        {modalAction === "resume" && (
          <div>
            <p>Resuming the event with ID: {currentEventId}.</p>
            {errorMessage && (
              <div className="text-red-500 mt-2">{errorMessage}</div>
            )}
            <button
              className="px-4 py-2 bg-green-500 text-white rounded"
              onClick={handleEventAction}
            >
              Resume
            </button>
          </div>
        )}
      </Modal>

      <Modal
        show={modalIsOpen && modalAction === "create"}
        onHide={closeCreateEventModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="mb-3">
              <label htmlFor="eventTitle" className="form-label">
                Event Title
              </label>
              <input
                type="text"
                className="form-control"
                id="eventTitle"
                value={newEventTitle}
                onChange={(e) => setNewEventTitle(e.target.value)}
                placeholder="Enter event title"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="eventType" className="form-label">
                Event Type
              </label>
              <select
                id="eventType"
                className="form-control"
                value={newEventType}
                onChange={(e) => setNewEventType(e.target.value)}
              >
                <option value="">Select Event Type</option>
                <option value="Quiz">Quiz</option>
                <option value="Group Discussion">Group Discussion</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="eventDate" className="form-label">
                Event Date
              </label>
              <input
                type="datetime-local"
                className="form-control"
                id="eventDate"
                value={newEventDate}
                onChange={(e) => setNewEventDate(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={closeCreateEventModal}>
            Close
          </button>
          <button className="btn btn-primary" onClick={handleCreateEvent}>
            Create Event
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpcomingEvents;
