import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthPage from "./AuthPage/AuthPage";
import FullPageLoading from "./FullPageLoading/FullPageLoading";
import UploadQuestions from "./containers/UploadQuestions/UploadQuestions";
import Layout from "./Layout/Layout";
import "./App.css";
import QuestionList from "./containers/QuestionList/QuestionList";
import QuizPage from "./containers/QuizPage/QuizPage";
import UpcomingEvents from "./containers/UpcomingEvents/UpcomingEvents";
import FinishPage from "./containers/FinishPage/FinishPage";
import CongratulationPage from "./containers/CongratulationPage/CongratulationPage";
import EventDetails from "./containers/EventDetails/EventDetails";
// import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const { authData } = useSelector((state) => state.loginSlice);

  if (!authData) {
    return <AuthPage />;
  }
  return (
    <Router>
      <Layout>
        <>
          <Suspense fallback={<FullPageLoading />}>
            <Routes>
              <Route path="/" exact element={<UpcomingEvents />} />
              <Route path="/Events" exact element={<UpcomingEvents />} />
              <Route path="/Events" exact element={<UpcomingEvents />} />
              <Route path="/event/:EventId" element={<EventDetails />} />
              <Route path="/Events/:EventId" exact element={<QuizPage />} />
              <Route path="/quiz" exact element={<QuizPage />} />
              <Route path="/quiz/:EventId" exact element={<QuizPage />} />
              <Route path="/upload" exact element={<UploadQuestions />} />
              <Route path="/questions" exact element={<QuestionList />} />

              <Route path="/finish" exact element={<FinishPage />} />
              <Route
                path="/congratulations/:EventId"
                element={<CongratulationPage />}
              />
            </Routes>
          </Suspense>
        </>
      </Layout>
    </Router>
  );
}

export default App;
